export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'My Hue',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    header: 'Apps & Pages',
  },
  {
    title: 'Support',
    route: 'support',
    icon: 'HelpCircleIcon',
  },
  {
    title: 'Terms of Service',
    route: 'tos',
    icon: 'BookIcon',
  },
  {
    header: 'Supports',
  },
  {
    title: 'Privacy Policy',
    route: 'privacy',
    icon: 'CameraOffIcon',
  },
]
